.cards {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 14px;
}

.cards div {
    background-color: #161f34;
    padding: 1rem;
    border-radius: 0.625rem;
}

.h2 {
    color: #fff;
    margin-bottom: 1rem;
}

.icon {
    color: #3fc0f8;
}

.list {
    list-style-type:  none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.item {
    color: slategray;
}

.title {
    color:  #3fc0f8;
    text-decoration: none;
}

.title:hover {
    color: #b8d9ee
}


/*@media (min-width: 768px) {*/
/*    .cards {*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*    }*/
/*}*/